import type { Dayjs } from 'dayjs'

import { LANGS_DATE_FORMATS, LANGS_DATE_CUSTOM_FORMATS, SERVER_DATE_FORMAT } from '@packages/date/formats'

export const useDate = () => {
  const { public: { locale } } = useRuntimeConfig()
  const lang = localeToLang(locale)
  const dayjs = useDayjs()

  /**
   * For available formats:
   * @see https://day.js.org/docs/en/display/format#list-of-localized-formats
   */
  const localeDateFormats = LANGS_DATE_FORMATS[lang as keyof typeof LANGS_DATE_FORMATS].formats

  const localeDateFormat = (date: any, parseFormat: any, outputFormat: any) => {
    const f = parseFormat || SERVER_DATE_FORMAT
    return dayjs(date, f).format(outputFormat || localeDateFormats.L)
  }

  const localeDateCustomFormats = computed(
    () => Object.entries(LANGS_DATE_CUSTOM_FORMATS)
      .reduce((obj, [name, format]) => ({
        ...obj,
        [name]: typeof format === 'object' ? format[lang as keyof object] : format,
      }), {}))

  const localeDate = (date: any, format: any) => {
    return dayjs(date).format(format || localeDateFormats.L)
  }

  const rangeWhenStatus = (start: any, end: any) => {
    const tripDateDayjs = dayjs(start)
    const returnDateDayjs = dayjs(end)
    const todayDayjs = dayjs()

    if (
      tripDateDayjs.isSame(todayDayjs, 'day') ||
      tripDateDayjs.isBefore(todayDayjs)
    ) {
      if (
        returnDateDayjs.isSame(todayDayjs, 'day') ||
        returnDateDayjs.isAfter(todayDayjs, 'day')
      ) {
        return 'ongoing'
      }

      return 'previous'
    }

    return 'upcoming'
  }

  const rangeFromTo = (from: Date | string, to: Date | string, format = localeDateFormats.LL) => {
    const fromFormatted = dayjs(from).format(format)
    const toFormatted = dayjs(to).format(format)

    return {
      from: fromFormatted,
      to: toFormatted,
      text: `${fromFormatted} - ${toFormatted}`,
    }
  }

  const isWeekend = (dayjsDate: Dayjs) => {
    return [0, 6].includes(dayjsDate.day())
  }

  return {
    localeDateFormat,
    localeDateFormats,
    localeDateCustomFormats,
    localeDate,
    rangeWhenStatus,
    rangeFromTo,
    isWeekend,
  }
}
